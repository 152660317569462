import React from 'react'
import Link from 'gatsby-link'

import Layout from '../components/Layout'

// import srm from '../img/srm.jpg'

export default class RecipientsPage extends React.Component {
  render() {
    return (
      <Layout>
        <section className="section">
          <div className="container-fluid">
            <div className="content">
              <h1>Scholarships - Past Recipients</h1>
              <div className="scholarships-grid-container">
                <div className="scholarship-title">
                  <b>Year</b>
                </div>
                <div className="scholarship-title">
                  <b>Merit Scholarship</b>
                </div>
                <div className="scholarship-title">
                  <b>Leadership Scholarship</b>
                </div>
                <div className="scholarship-title">
                  <b>Sarah Rogers McClure Scholarship</b>
                </div>
                <div className="scholarship-title">
                  <b>Philanthropy and Service Scholarship</b>
                </div>
                <div className="scholarship-title">
                  <b>Anne Louise Roach Legacy Scholarship</b>
                </div>
                <div className="scholarship-title">
                  <b>Francis J. Monigan Memorial Scholarship</b>
                </div>
                <div className="scholarship-title">
                  <b>Texas Leadership Scholarship</b>
                </div>

                <div>
                  <b className="scholarship-yr">2024</b>
                </div>
                <div className="scholarship-winner">
                  <h2>Madison Thomas</h2>
                  <span>Purdue University - Alpha</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Courtney Wilmoth</h2>
                  <span>University of Arkansas - Kappa</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Dori Wilson</h2>
                  <span>University of Dayton - Gamma</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Morganne Neuman</h2>
                  <span>University of Akron - Alpha Omega</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Catherin Ardizzone</h2>
                  <span>University of Buffalo - Alpha Omicron</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Brooklyn Wentz</h2>
                  <span>University of Cincinnati - Alpha Beta</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Iliana Gabino</h2>
                  <span>University of Houston - Upsilon</span>
                </div>

                <div>
                  <b className="scholarship-yr">2023</b>
                </div>
                <div className="scholarship-winner">
                  <h2>Morgan Fink</h2>
                  <span>University of Florida - Tau</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Hannah Spillett</h2>
                  <span>University of Toledo - Delta</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Tejaswini Haraniya</h2>
                  <span>Case Western Reserve University - Omicron</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Wendy Hoffert</h2>
                  <span>University of Arkansas - Kappa</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Judy Chen</h2>
                  <span>University of Colorado Boulder - Alpha Mu</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Madalyn Reardon</h2>
                  <span>Penn State University - Lambda</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Jazlyn Marvin</h2>
                  <span></span>
                </div>

                <div>
                  <b className="scholarship-yr">2022</b>
                </div>
                <div className="scholarship-winner">
                  <h2>Kanika Yadav</h2>
                  <span>University of Pittsburgh - Zeta</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Maria Machon</h2>
                  <span>Texas A&amp;M University - Alpha Phi</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Holly Wingren</h2>
                  <span>
                    University of Illinois at Urbana-Champaign - Alpha Epsilon
                  </span>
                </div>
                <div className="scholarship-winner">
                  <h2>Katherine Brown</h2>
                  <span>University of Connecticut - Theta</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Jessica Gill</h2>
                  <span>University of Dayton - Gamma</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Larissa Wermers</h2>
                  <span>Universit of Michigan - Eta</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Ana Alveraz / Ashlyn Brook Wilhite</h2>
                  <span></span>
                </div>

                <div>
                  <b className="scholarship-yr">2021</b>
                </div>
                <div className="scholarship-winner">
                  <h2>Isabella Gayoso</h2>
                  <span>Pennsylvania State University - Lambda</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Alyson Neaves Jimenez</h2>
                  <span>Arizona State University - Alpha Lambda</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Jennifer Conde</h2>
                  <span>UC Berkely - Alpha Theta</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Pinky Arunwattanamongkol</h2>
                  <span>University of Cincinnati - Alpha Beta</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Lindsay Eichhold</h2>
                  <span>University of Louisville - Alpha Gamma</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Alexis Brzezinski</h2>
                  <span>University of Michigan - Eta</span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>

                <div>
                  <b className="scholarship-yr">2020</b>
                </div>
                <div className="scholarship-winner">
                  <h2>Hannah Todd</h2>
                  <span>Alpha Beta - University of Cincinnati</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Brooke Lanier</h2>
                  <span>Alpha Sigma - University of Nevada - Reno</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Logan Smesrud</h2>
                  <span>Sigma - Oregon State University</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Stephanie Lee</h2>
                  <span>Nu - University of California, Los Angeles</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Paige Hardt</h2>
                  <span>Alpha Pi - University of Wyoming</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Renee Pelan</h2>
                  <span>Alpha Zeta - Wright State University</span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>

                <div>
                  <b className="scholarship-yr">2019</b>
                </div>
                <div className="scholarship-winner">
                  <h2>Brianna Mancuso</h2>
                  <span>Theta - University of Connecticut</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Alison Michalke</h2>
                  <span>Alpha Beta - University of Cincinnati</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Mckenzie Foster</h2>
                  <span>Sigma - Oregon State University</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Megan Chang</h2>
                  <span>Chi - University of California, San Diego</span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>

                <div>
                  <b className="scholarship-yr">2018</b>
                </div>
                <div className="scholarship-winner">
                  <h2>Riley Blumenfield</h2>
                  <span>Theta - University of Connecticut</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Camille Stuczynski</h2>
                  <span>Alpha Theta - University of California, Berkley</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Amy Ostrem</h2>
                  <span>Alpha Beta - University of Cincinnati</span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>

                <div>
                  <b className="scholarship-yr">2017</b>
                </div>
                <div className="scholarship-winner">
                  <h2>Meredith Jones</h2>
                  <span>Alpha Gamma - University of Louisville</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Madison Farrell</h2>
                  <span>Alpha Delta - Michigan State University</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Miranda Vargas</h2>
                  <span>Delta - University of Toledo</span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>

                <div>
                  <b className="scholarship-yr">2016</b>
                </div>
                <div className="scholarship-winner">
                  <h2>Rachel Rosenzweig</h2>
                  <span>Mu - University of Washington</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Mary Turner</h2>
                  <span>Tau - University of Florida</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Tayla Mandelkern</h2>
                  <span>Theta - University of Connecticut</span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>

                <div>
                  <b className="scholarship-yr">2015</b>
                </div>
                <div className="scholarship-winner">
                  <h2>Morgan Miller</h2>
                  <span>Alpha Zeta - Wright State University</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Bridget Fricke</h2>
                  <span>Alpha Zeta - Wright State University</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Sarah Abdelaziz</h2>
                  <span>Xi - Rutgers University</span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>

                <div>
                  <b className="scholarship-yr">2014</b>
                </div>
                <div className="scholarship-winner">
                  <h2>Kendall Huddleston</h2>
                  <span>Epsilon - University of Kentucky</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Alexandra Furnish</h2>
                  <span>Sigma - Oregon State University</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Jessica Berman</h2>
                  <span>Delta - University of Toledo</span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>

                <div>
                  <b className="scholarship-yr">2013</b>
                </div>
                <div className="scholarship-winner">
                  <h2>Samantha Manning</h2>
                  <span>Alpha Gamma - University of Louisville</span>
                  <h2>Shruti Sudhakar</h2>
                  <span>Case Western Reserve University - Omicron</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Alexandra Brogan</h2>
                  <span>Gamma - University of Dayton</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Ingrid Bauer</h2>
                  <span>Gamma - University of Dayton</span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>

                <div>
                  <b className="scholarship-yr">2012</b>
                </div>
                <div className="scholarship-winner">
                  <h2>Anna Koch</h2>
                  <span>Sigma - Oregon State University</span>
                  <h2>Ashley Biales-Wise</h2>
                  <span>Beta - The Ohio State University</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Audrey Oldenkamp</h2>
                  <span>Sigma - Oregon State University</span>
                  <h2>Amanda Grimes</h2>
                  <span>Alpha - Purdue University</span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>

                <div>
                  <b className="scholarship-yr">2011</b>
                </div>
                <div className="scholarship-winner">
                  <h2>Ameé Salois</h2>
                  <span>University of Arkansas</span>
                  <h2>Holly Mahoney</h2>
                  <span>Gamma - University of Dayton</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Tricia Fuge</h2>
                  <span>Beta - The Ohio State University</span>
                  <h2>Annie Nguyen</h2>
                  <span>Chi - University of California, San Diego</span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>

                <div>
                  <b className="scholarship-yr">2010</b>
                </div>
                <div className="scholarship-winner">
                  <h2>Katie Trase</h2>
                  <span>Beta - The Ohio State University</span>
                  <h2>A.J. Bonilla</h2>
                  <span>Tau - University of Tau</span>
                </div>
                <div className="scholarship-winner">
                  <h2>Catrina Vargo</h2>
                  <span>Lambda - Penn State University</span>
                  <h2>Kelley Dick Meyer</h2>
                  <span>Alpha - Purdue University</span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>

                <div>
                  <b className="scholarship-yr">2009</b>
                </div>
                <div className="scholarship-winner">
                  <h2>Nicole Varnerin</h2>
                  <span>Omicron - Case Western Reserve University</span>
                  <h2>Stephanie Capiello</h2>
                  <span>Nu - University of California, Los Angelos</span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>

                <div>
                  <b className="scholarship-yr">2008</b>
                </div>
                <div className="scholarship-winner">
                  <h2>Brittany Collins</h2>
                  <span>Gamma - University of Dayton</span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>

                <div>
                  <b className="scholarship-yr">2007</b>
                </div>
                <div className="scholarship-winner">
                  <h2>Rachel Chicci</h2>
                  <span>Lambda - Penn State University</span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>

                <div>
                  <b className="scholarship-yr">2006</b>
                </div>
                <div className="scholarship-winner">
                  <h2>Lauren Buerkle</h2>
                  <span>Omicron - Case Western Reserve University</span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
                <div className="scholarship-winner">
                  <h2></h2>
                  <span></span>
                </div>
              </div>

              <p>
                <Link to="/">← Go Home</Link>
              </p>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
